import TicketShop from "components/ticket-shop";
import React from "react";

export function FilteredTicketshop({ id }) {
  const scriptString = `<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?salesChannel=whitelabel-website" data-fixr-shop-id="${id}" data-fixr-theme="light"></script>`;

  return (
    <div>
      <TicketShop scriptString={scriptString} />
    </div>
  );
}
