import React, { useRef, useState } from "react";
import Helmet from "react-helmet";
import TicketShop from "components/ticket-shop";

// comment

import { Page } from "components/Page";
import { MotionImage } from "components/MotionImage";
import { FilteredTicketshop } from "components/FilteredTicketshop";

const IndexPage = (p) => {
  return (
    <Page overlayHeader>
      <Helmet
        htmlAttributes={{
          lang: "en-GB",
        }}
        title={"Fresh2Death"}
        meta={[
          {
            name: `description`,
            content: "Fresh2Death",
          },
          {
            property: `og:url`,
            content: `https://fresh2deathuk.com`,
          },
          {
            property: `og:title`,
            content: "Fresh2Death",
          },
          {
            property: `og:description`,
            content: "",
          },
          {
            property: `og:image`,
            content: ``,
          },
          {
            name: "facebook-domain-verification",
            content: "hbz9kgizuwuwpiirlzal9fclzec6bp",
          },
        ]}
      />
      <div className="relative">
        <span
          className="absolute opacity-40 inset-0 w-full h-full"
          style={{
            background:
              "linear-gradient(180deg, rgba(0, 0, 0, 0.42) 5.45%, rgba(0, 0, 0, 0) 25.31%, rgba(0, 0, 0, 0) 70.71%, rgba(0, 0, 0, 0.4) 95.6%)",
          }}
        ></span>
        <MotionImage
          className="w-full object-cover"
          style={{ maxHeight: 800, aspectRatio: 1400 / 761 }}
          src="/images/rockstar-frontpage.jpg"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        />
      </div>
      <section className="pt-16 pb-16" style={{ background: "#8ecf99" }}>
        <div className="container mx-auto">
          <div>
            <FilteredTicketshop id="f8e48caf-038f-4062-b6b5-e963e15a7183" />
          </div>
        </div>
      </section>
    </Page>
  );
};

export default IndexPage;
